import DashboardLayout from '../layouts/dashboard';
import { PageIamges } from '../mock/me';
import AppView from '../sections/overview/view/app-view';
import React, { Suspense } from 'react';
export type PaletteMode = 'light' | 'dark';
// ----------------------------------------------------------------------

interface HeaderProps {
  mode: PaletteMode;
  toggleColorMode: () => void;

}
export default function AppPage({ mode, toggleColorMode }: HeaderProps) {

  return (
    <>
      <title>James mensah </title>
      <DashboardLayout mode={mode} toggleColorMode={toggleColorMode} >
        <Suspense fallback={<div>Loading ...</div>}>
          <AppView />
          
          <div className='fixed top-0 right-0 -translate-y-1/3 translate-x-1/2 blur-3xl opacity-15 pointer-events-none z-0'>
            <img src={PageIamges.backgroundAbsolute} className="max-w-none" width="852" height="582" alt="Illustration" />
          </div>
          <div className="fixed top-0 left-0 rotate-90 -translate-x-3/4 -scale-x-100 blur-3xl opacity-70 pointer-events-none z-0" aria-hidden="true">
            <img src={PageIamges.backgroundAbsolute} className="max-w-none" width="852" height="582" alt="Illustration" />
          </div>


        </Suspense>
      </DashboardLayout>

    </>
  );
}
