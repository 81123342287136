import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { BsBoxArrowUpRight, BsGithub } from "react-icons/bs";
import { projectsProps } from "../../mock/me";

export const ProjectXsL: React.FC<projectsProps> = ({
  title,
  detail,
  link,
  github,
  image,
  tools,
}) => {

  return (
    <Box
      sx={(theme) => ({
        width: "95%",
        height: "200px",
       
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      })}
      className="project-container"
    >
      <img
        alt=""
        className="d_work_image"
        style={{
          width: "85%",
          height: "160px",
          borderRadius: "10px"
        }}
        src={image}
      ></img>
      <Stack
        className="project-content"
        direction="column"
        gap={2}
        sx={(theme) => ({
          width: "100%",
          height: "200px",
          padding: "10px",
          boxShadow: theme.palette.mode === "dark" ? '' :
          'rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px'
        ,
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.grey[700]
              : theme.palette.grey[100],
          borderRadius: '10px',
          overflow: "hidden"
        })}
      >
        <Typography
          variant="h5"
          sx={(theme) => ({
            color:
              theme.palette.mode === "dark"
                ? theme.palette.grey[200]
                : "rgb(5, 175, 133)",
            fontWeight: "bold",
            fontSize: "13px",
          })}
        >
          {title}
        </Typography>
        <Box
          className="d_work_p_c"
          sx={(theme) => ({
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.grey[600]
                : "rgb(5, 175, 133)",

            padding: "5px",
          })}
        >
          <Typography
            sx={(theme) => ({
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[300]
                  : theme.palette.grey[100],
              fontSize: "8px",
            })}
            variant="body2"
          >
            {detail}
          </Typography>
        </Box>
        <Stack direction="column" gap={1}>
          <Grid container direction="row" spacing={1}>
            {tools &&
              tools.map((tool, index) => (
                <Grid item key={index} xs={3}>
                  <Typography variant="body2" sx={{ fontSize: "9px" }}>
                    {tool}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </Stack>
        <Stack gap={1} direction="row" className="d_work_p_link">
          {link && (
            <a href={link} target="_blank" rel="noopener noreferrer" title={`Explore more about ${title}- ${link}`}>
              <BsBoxArrowUpRight color="rgb(5, 175, 133)" />
            </a>
          )}

          {github && (
            github && <a href={github} target="_blank" rel="noopener noreferrer" title={`Check out ${title}'s codebase on GitHub - ${github}`}>
              <BsGithub color="rgb(5, 175, 133)" />
            </a>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
