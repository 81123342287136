import { Box, Typography } from "@mui/material"
import { useState } from "react"

export const ProjectContent = ({ detail }: { detail: string }) => {
    return (
        <Box

            className="d_work_p_c"
            sx={(theme) => ({
                backgroundColor: theme.palette.mode === "dark" ? "#1d1d26" : '',
                background: theme.palette.mode === "dark" ? '' :
                    `radial-gradient(circle, rgba(205,207,219,1) 0%, rgba(183,189,247,1) 100%)`,
                minHeight: "150px",
                padding: "10px 8px",
                boxShadow: theme.palette.mode === "dark" ? '' :
                    'rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px'

            })}
        >
            <Typography sx={(theme) => ({
                color: theme.palette.mode === "dark" ?
                    theme.palette.grey[300] : theme.palette.grey[900],
                fontSize: '14px',
                fontWeight: 700

            })}
                variant="subtitle1">

                {detail}
            </Typography>
        </Box>
    )
}

export const ProjectIcon = ({ image }: { image?: string }) => {
    const [grow, setGrow] = useState(0.25)

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { clientX, clientY, currentTarget } = event;
        const { left, top, width, height } = currentTarget.getBoundingClientRect();
        const x = clientX - left;
        const y = clientY - top;
        const xPercent = x / width;
        const yPercent = y / height;
        const newGrow = Math.min(0.9, Math.max(0.25, xPercent * yPercent * 2));
        setGrow(newGrow);
    };
    return (
        <Box
            sx={(theme) => ({
                width: "55%",
                border: ` ${theme.palette.mode === "dark" ? `1px solid ${theme.palette.grey[700]} `
                    : ''}`,
                overflow: "hidden",
                borderRadius: "15px",
                boxShadow: theme.palette.mode === "dark" ? `rgba(0, 0, 0, ${grow - 0.10}) 0px 2px 8px` : `rgba(255, 255, 255,${grow - 0.10} ) 0px 1px 1px 0px inset, 
                rgba(50, 50, 93, ${grow}) 0px 50px 100px -20px, rgba(0, 0, 0,  ${grow}) 0px 30px 60px -20px`,
            })}
            className="work_imagecontainer"
            onMouseMove={handleMouseMove}
        >
            <img
                className="d_work_image"
                style={{ borderRadius: "15px" }}
                src={image}
                alt=""
            >

            </img>
        </Box>
    )
}