


import { Stack, Box, Typography } from "@mui/material"
import { projectsList, projectsProps } from "../../mock/me"
import { useResponsive } from "../../hooks/use-responsive";
import "./project.css"
import { ProjectL } from "./project-left"
import { ProjectXsL } from "./project-mobile";
import { ProjectR } from "./project-right";
import AnimatedContainer from "../../common-ui/GrowContainer";
const Projects = () => {
    const upLg = useResponsive('up', 'lg');


    return (
        <Stack id="works" direction="column" gap={10} sx={{ margin: "60px 0px" }}>

            <Stack className="project-h" direction="row" alignItems="center" justifyContent="left" sx={{width:'95%'}}>
                <Box sx={{
                    width: upLg ? "530px" : '100%',
                }}>
                    <Typography variant="h3"

                        sx={(theme) => ({
                            color: theme.palette.mode === "dark" ? "rgb(5, 175, 133)" : theme.palette.grey[900],
                            fontSize: upLg ? "30px !important" : "14px",
                            fontWeight: '700 !important',
                            width: upLg ? "530px" : '100%',

                        })}

                        id="projectheader"
                        className="projec_intro targets-container">Some Projects I have Built </Typography>
                </Box>
</Stack>

            <Stack gap={   upLg ?
14: 3}
                className=""
                sx={
                    () => ({
                        width: "100%",
                        minHeight: `${window.innerHeight}px`

                    })
                }>


                {projectsList && projectsList.map((data: projectsProps, index: number) => {
                    return (
                        <Box key={index}>

                            {
                                upLg ?

                                    index % 2 === 0 ?
                                        <AnimatedContainer >
                                            <ProjectR
                                                title={data.title}
                                                detail={data.detail}
                                                link={data.link}
                                                github={data.github}
                                                image={data.image}
                                                tools={data.tools}
                                            />
                                        </AnimatedContainer>


                                        :
                                        <AnimatedContainer>
                                            <ProjectL
                                                title={data.title}
                                                detail={data.detail}
                                                link={data.link}
                                                github={data.github}
                                                image={data.image}
                                                tools={data.tools}
                                            />
                                        </AnimatedContainer>




                                    :
                                    <AnimatedContainer>
                                        <ProjectXsL
                                            title={data.title}
                                            detail={data.detail}
                                            link={data.link}
                                            github={data.github}
                                            image={data.image}
                                            tools={data.tools}
                                        /></AnimatedContainer>

                            }

                        </Box>
                    )
                })

                }


            </Stack>

        </Stack>

    )
}


export default Projects