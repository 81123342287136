
type skillType = "language" | "devtools" | "frameworks" | "os"



interface skillProps {
    type: skillType,
    name: string, image?: string
    dark?: string,
    light?: string
}
interface experienceProps {
    title: {
        image?: string,
        text: string
    }
    date: string
    detail: string
    skills?: skillProps[]


}

export const PageIamges = {
    backgroundAbsolute: 'assets/images/shape.svg'
}

export const aboutMe = {

    photo: "assets/images/james-mensah.jpg",
    number: "+233 557471572",
    gmail: "jamesmensahcoded@gmail.com",
    whatsapp: "+233 557471572",
    telegram: "+233 557471572",
    github: "https://github.com/james-mensa"

}

export const mySelf: string = `I am a full stack developer specializing in web and mobile development, dedicated to creating user-centric and innovative digital solutions. With an interest in Web3 dApps and blockchain technologies, I am passionate about continuous learning and evolving with the dynamic tech industry.`

export const experienceData: experienceProps[] = [
    {
        title: {
            text: "Software developer at ZkBlock (Zkrypto)",
            image: "assets/images/zkrypto.png"
        },
        date: "2023-PRESENT",

        skills: [

            {
                type: "language", name: "Java",
                dark: "#17171f",
                light: "#ebecf5"
            }
            ,
            {
                type: "language", name: "Typescript",
                dark: "#17171f",
                light: "#ebecf5"
            },

            {
                type: "language", name: "Solidity", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "frameworks", name: "React js", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "frameworks", name: "Next js", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "frameworks", name: "Node js", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "frameworks", name: "React Native", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "frameworks", name: "PostgreSql", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "devtools", name: "Docker", dark: "#17171f",
                light: "#ebecf5"
            },

            {
                type: "devtools", name: "Blockchain", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "devtools", name: "Kubernetes", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "os",
                name: "Linux(Ubuntu)", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "devtools",
                name: "Gitlab", dark: "#17171f",
                light: "#ebecf5"
            }
        ],
        detail: `I collaborated on the development of a block explorer web application utilizing React.js, Next.js, Ether.js, and Web3 technologies, enabling the monitoring of internal transactions on both public and private Ethereum client networks. Additionally, I worked on optimizing transaction execution throughput on Hyperledger Besu blockchain client by building plugin extensions.
My expertise extends to smart contract development, ensuring efficient and reliable deployment using Docker and Bash scripting. I also contributed to cross-platform mobile app development using React Native for the ZkWallet app, joining the team during the later stages of the development process.
As a team player, I actively contribute to all projects at Zkblock, fostering a collaborative environment and driving innovative solutions.`,

    },

    {
        title: {
            text: "Full Stack developer at badu devapps",
            image: "assets/images/baduapp.png"
        },
        date: "2021-2023",
        detail: `Built client applications using React.js for front-end development, Tailwind CSS for styling, and Webpack for asset management. On the backend, I used Node.js, MongoDB, PostgreSQL, and AWS (Route 53, S3). I also contributed to Flutter-based mobile app development for cross-platform solutions.`,

        skills: [

            {
                type: "language", name: "Java", dark: "#17171f",
                light: "#ebecf5"
            }
            ,
            {
                type: "language", name: "Javascript", dark: "#17171f",
                light: "#ebecf5"
            },

            {
                type: "language", name: "Python", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "language", name: "Dart", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "frameworks", name: "React js", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "frameworks", name: "Node js", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "frameworks", name: "React Native", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "frameworks", name: "Flutter", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "frameworks", name: "Sqlite", dark: "#17171f",
                light: "#ebecf5"
            },

            {
                type: "frameworks", name: "Mysql", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "frameworks", name: "Java swing", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "frameworks", name: "Spring Boot", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "frameworks", name: "MongoDB", dark: "#17171f",
                light: "#ebecf5"
            },

            {
                type: "devtools",
                name: "Aws", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "devtools",
                name: "Nextlify", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "os",
                name: "Mac && windows ", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "devtools",
                name: "Github", dark: "#17171f",
                light: "#ebecf5"
            }
        ],
    },
    {
        title: {
            text: "Freelancer at Upwork",
            image: "assets/images/upwork.png"
        },
        date: "2019-2022",
        detail: `I have freelance experience using WordPress, Shopify, HTML, and JavaScript to create and customize websites for various clients and colleagues during my time as a student pursuing my BSc in Computer Science at the University of Ghana, Legon`,

        skills: [

            {
                type: "language", name: "Java", dark: "#17171f",
                light: "#ebecf5"
            }
            ,
            {
                type: "language", name: "Javascript", dark: "#17171f",
                light: "#ebecf5"
            },



            {
                type: "frameworks", name: "Html", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "frameworks", name: "CSS", dark: "#17171f",
                light: "#ebecf5"
            },


            {
                type: "frameworks", name: "Mysql", dark: "#17171f",
                light: "#ebecf5"
            },



            {
                type: "devtools",
                name: "Wordpress", dark: "#17171f",
                light: "#ebecf5"
            },
            {
                type: "devtools",
                name: "Shopify", dark: "#17171f",
                light: "#ebecf5"
            }, {
                type: "devtools",
                name: "Github"
            },
            {
                type: "os",
                name: "windows ", dark: "#17171f",
                light: "#ebecf5"
            }

        ],
    }

    //     , {
    //         title: {
    //             text: "Bachelor of science in computer science",   image:"assets/images/ug.png"
    //         },

    //         date: "2019-2023",
    //       

    //     },

]


export interface projectsProps {

    title: string;
    detail: string;
    github?: string;
    link?: string;
    tools?: string[]
    image?: string
}

export const projectsList: projectsProps[] = [
    {
        title: "My portfolio",
        detail: `This platform is designed to showcase my professional journey, highlight my expertise in full stack development, web and mobile development, and Web3 technologies. It serves as a comprehensive display of my skills, projects, and experiences.`,
        github: "https://github.com/jamesDeDeveloper/my-portfolio",
        link: "https://jamesmensahme.com/",
        tools: [
            "Javascript",
            "Node js",
            "Next js",
            "Aws"
        ],
        image: "assets/images/portfolio.png"

    },
    {
        title: "Badu Devapps",
        detail: `Collaborated on the design and development of enterprise-level applications, ensuring robust performance and scalability.Played a key role in building and maintaining BadudevApps' officia
l website, leveraging modern web technologies to enhance user experience and functionality`,
        link: "https://www.badudevapps.com/",
        tools: [
            "Typecript",
            "Node js",
            "Next js",
            "Aws"
        ],
        image: "assets/images/badudevs.png"

    }
    , {
        title: "Rixos Hotel",
        detail: `At Rixos, we pride ourselves on delivering impeccable service that goes beyond your expectations. Whether you're here for business or leisure, we aim to make your stay with us truly exceptional.Nestled in the heart of Greater Accra, our boutique hotel offers a perfect blend of modern sophistication and warm hospitality`,
        link: "https://rixoscomfort.netlify.app/",
        tools: [
            "Javascript",
            "Node js",
            "Next js",
            "Aws"
        ],
        image: "assets/images/rixos.png"

    }
    , {
        title: "MY ID FINDER(GH)",
        detail: `Find, Post, Reunite with Real Owner - Let's Save the Stress .
               Welcome to IDRescue, the ultimate solution for lost ID card recovery! Have you lost your ID card? Or perhaps you've found one? We're here to help you reunite with your lost ID card or connect with its rightful owner`,
        link: "https://myidrescue.netlify.app/",
        tools: [
            "Javascript",
            "Node js",
            "React js",
            "MongoDB",
            "Nextlify",
        ],
        image: "assets/images/myidfinder.png"

    }
    , {
        title: "Myshareexclusive",
        detail: `Learn from Experts from the comfort of your home.
                   The beginning of a new age .The journey to full Industrization and Digitalization
                   
                   Be incharge ,Be your own BOSS`,
        link: "https://myshareexclusive.live/",
        tools: [
            "Javascript",
            "Node js",
            "AWS",
            "React js",
            "MongoDB",
            "NameCheap",
        ],
        image: "assets/images/myshare.png"

    }


]

