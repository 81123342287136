import ToggleColorMode from "../component/ToggleColorMode";
import { contacts } from "../mock";
import { Box, Stack } from "@mui/material";
import React from "react";
import { HoverIcon } from "./HoverIcon";
import { NavBoxs } from "./BoxNavs";
import { isLargeScreen } from "../utils/types";

type palette = "dark" | "light";
interface props {
  mode: palette;
  toggleColorMode: () => void;
}

export const LeftLinks: React.FC<props> = ({ mode, toggleColorMode }) => {
  return (
    <Stack
      className="link-icons"
      direction="column"
      alignItems="flex-start"
      justifyContent="flex-end"
      gap={1}
      sx={{
        height: `${window.innerHeight}px`,
      }}
      id="l_stick_contact"
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        spacing={3}
        sx={{
          height: "70%",
          padding: "10px 0",
          paddingBottom: "50px",
        }}
      >
        <Stack
          gap={isLargeScreen ? 1.5 : 1}
          direction="column"
          className="left-nav-links"
          alignItems="center"
        >
          <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
          {contacts &&
            contacts.map((data, id) => {
              return (
                <Box key={id}>
                  <HoverIcon
                    icon={data.icon}
                    label={data.name}
                    link={data.link}
                  />
                </Box>
              );
            })}
        </Stack>

        <NavBoxs />
      </Stack>
    </Stack>
  );
};
