import React, { ReactNode, useEffect } from 'react';
import { motion, useAnimation, AnimationControls } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface AnimatedContainerProps {
  children: ReactNode;
}

const AnimatedContainer: React.FC<AnimatedContainerProps> = ({ children }) => {
  const controls: AnimationControls = useAnimation();
  const { ref, inView, entry } = useInView({
    threshold: [0, 0.5, 1],
  });

  useEffect(() => {
    if (entry) {
      const { boundingClientRect, intersectionRatio } = entry;
      const centerY = boundingClientRect.y + boundingClientRect.height / 2;
      const centerX = boundingClientRect.x + boundingClientRect.width / 2;
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      const centerViewportY = viewportHeight / 2;
      const centerViewportX = viewportWidth / 2;

      let scale, rotateX, rotateY;

      if (inView) {
        if (intersectionRatio >= 0.6) {
          scale = 1;
          rotateX = 0;
          rotateY = 0;
        } else {
          scale = Math.min(1, 0.6 + intersectionRatio * 0.3);
          rotateX = ((centerY - centerViewportY) / viewportHeight) * 20;
          rotateY = ((centerX - centerViewportX) / viewportWidth) * 20;
        }
      } else {
        scale = Math.max(0.6, 1 - (Math.abs(centerY - centerViewportY) / viewportHeight) * 0.3);
        rotateX = ((centerY - centerViewportY) / viewportHeight) * 15;
        rotateY = ((centerX - centerViewportX) / viewportWidth) * 15;
      }

      controls.start({
        scale,
        rotateX,
        rotateY,
        transition: { duration: 0.5 },
      });
    }
  }, [inView, entry, controls]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial={{ scale: 1, rotateX: 0, rotateY: 0 }}
      style={{ perspective: 1000 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedContainer;
