import React, { useState, useEffect, useRef } from 'react';

const DEFAULT_BASE_COLOR = 'rgba(158, 203, 255,0.5)';
const INITIAL_CIRCLE_COLOR = 'none';
const CIRCLE_RADIUS = 17;
const BORDER_COLOR = 'rgba(246, 184, 70, 0.4)';

type CircleProps = {
    x: number;
    y: number;
    radius: number;
    color: string;
    borderWidth: number;
    borderColor: string;
    borderStyle: string;
    opacity: number;
    isHovering: boolean;
};

const MouseInteraction: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const animationRef = useRef<number | null>(null);
    const [circleProps, setCircleProps] = useState<CircleProps>({
        x: 0,
        y: 0,
        radius: CIRCLE_RADIUS,
        color: INITIAL_CIRCLE_COLOR,
        borderWidth: 0,
        borderColor: 'none',
        borderStyle: 'none',
        opacity: 0.7,
        isHovering: false,
    });

    const targetPropsRef = useRef<CircleProps>(circleProps);

    const ease = 0.08;

    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            const { clientX, clientY } = event;
            const canvas = canvasRef.current;
            if (!canvas) return;
            const rect = canvas.getBoundingClientRect();
            const x = clientX - rect.left;
            const y = clientY - rect.top;

            let hovering = false;
            let baseColor = DEFAULT_BASE_COLOR;
            let circleSize = CIRCLE_RADIUS;

            const targetDivs = document.querySelectorAll('.mouse_interaction-target');
            const nonTargetDivs = document.querySelectorAll('div:not(.mouse_interaction-target)');

            nonTargetDivs.forEach(div => {
                const divRect = div.getBoundingClientRect();
                if (clientX >= divRect.left && clientX <= divRect.right &&
                    clientY >= divRect.top && clientY <= divRect.bottom) {
                    baseColor = window.getComputedStyle(div).backgroundColor;
                }
            });

            targetDivs.forEach(div => {
                const divRect = div.getBoundingClientRect();
                if (clientX >= divRect.left && clientX <= divRect.right &&
                    clientY >= divRect.top && clientY <= divRect.bottom) {
                    hovering = true;
                    baseColor = window.getComputedStyle(div).backgroundColor;
                    circleSize = 45;
                }
            });

            const gradientColor = `radial-gradient(circle, rgba(186,174,238,1) 10%, ${baseColor} 100%)`;

            targetPropsRef.current = {
                x,
                y,
                radius: circleSize,
                color: hovering ? 'transparent' : gradientColor,
                borderWidth: hovering ? 2 : 0,
                borderColor: hovering ? BORDER_COLOR : 'none',
                borderStyle: hovering ? 'solid' : 'none',
                opacity: hovering ? 1 : 0.7,
                isHovering: hovering,
            };
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        const animate = () => {
            setCircleProps(prevProps => {
                const targetProps = targetPropsRef.current;

              
                const newProps: CircleProps = {
                    x: prevProps.x + (targetProps.x - prevProps.x) * ease,
                    y: prevProps.y + (targetProps.y - prevProps.y) * ease,
                    radius: prevProps.radius + (targetProps.radius - prevProps.radius) * ease,
                    color: targetProps.color,
                    borderWidth: prevProps.borderWidth + (targetProps.borderWidth - prevProps.borderWidth) * ease,
                    borderColor: targetProps.borderColor,
                    borderStyle: targetProps.borderStyle,
                    opacity: prevProps.opacity + (targetProps.opacity - prevProps.opacity) * ease,
                    isHovering: targetProps.isHovering,
                };

                return newProps;
            });

            animationRef.current = requestAnimationFrame(animate);
        };

        animationRef.current = requestAnimationFrame(animate);

        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');

        if (!canvas || !context) return;

        const { x, y, radius, color, borderWidth, borderColor, borderStyle, opacity } = circleProps;

        context.clearRect(0, 0, canvas.width, canvas.height);

        context.globalAlpha = opacity;
        context.beginPath();
        context.arc(x, y, radius, 0, Math.PI * 2, false);

        if (color.startsWith('radial-gradient')) {
            const gradient = context.createRadialGradient(x, y, radius / 2, x, y, radius);
            gradient.addColorStop(0, 'rgba(186,174,238,0.5)');
            gradient.addColorStop(1, DEFAULT_BASE_COLOR);
            context.fillStyle = gradient;
        } else {
            context.fillStyle = color;
        }

        context.fill();

        if (borderStyle === 'solid') {
            context.lineWidth = borderWidth;
            context.strokeStyle = borderColor;
            context.stroke();
        }
    }, [circleProps]);

    return (
        <canvas ref={canvasRef} width={window.innerWidth} height={window.innerHeight} style={{ position: 'fixed', top: 0, left: 0, zIndex: 1000, pointerEvents: 'none' }} />
    );
};

export default MouseInteraction;
