import React from "react";
import { scrollToComponent, scrollToTop } from "../utils/effects";
import { Stack } from "@mui/material";
import { isLargeScreen } from "../utils/types";

interface NavBoxItemProps {
  onClick: () => void;
  id: string;
}

const NavBoxItem: React.FC<NavBoxItemProps> = ({ onClick, id }) => {
  const BOX_SIZE = isLargeScreen ? '19px' : '15px';

  return (
    <span
      className="nav-box mouse_interaction-target"
      style={{ width: BOX_SIZE, height: BOX_SIZE }}
      onClick={onClick}
      id={id}
    ></span>
  );
};

export const NavBoxs: React.FC = () => {
  return (
    <Stack alignItems="center" spacing={3} className="d_fix_contact_line fix_contact_l">
      <NavBoxItem onClick={scrollToTop} id="about_id" />
      <NavBoxItem onClick={() => scrollToComponent("experience")} id="experience_id" />
      <NavBoxItem onClick={() => scrollToComponent("works")} id="work_id" />
      <NavBoxItem onClick={() => scrollToComponent("contactme")} id="contact_id" />
    </Stack>
  );
};
