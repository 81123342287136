
import Profile from '../../../sections/profile';
import Container from '@mui/material/Container';
import { useResponsive } from '../../../hooks/use-responsive';

import React, { useEffect } from 'react';
import { NavContactDesktop } from '../../../layouts/dashboard/nav-content';
import Experience from '../../experience';
import Projects from '../../projects';
import Contact from '../../contact';
import { componentonMount } from '../../../utils/effects';
import { VerticalGap } from '../../../models/gap';


export default function AppView() {

  const lgUp = useResponsive('up', 'lg');
  useEffect(() => {

    componentonMount()
  }, [])
  return (
    <Container
      className='z-10'
      maxWidth="xl" sx={{
        display: 'flex',
        flexDirection: 'column',
        width: lgUp ? "97%" : "100%",
        padding: 0
      }}>
      {
        lgUp && <NavContactDesktop />
      }
      <Profile />
      <Experience />
      <Projects />
      <VerticalGap h={lgUp ? 200 :30}/>
      <Contact />
    </Container>

  );
}
