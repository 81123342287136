

import { Stack, Typography } from "@mui/material";
import React from "react";
import { BsBoxArrowUpRight, BsGithub } from "react-icons/bs";
import { projectsProps } from "../../mock/me"
import { ProjectContent, ProjectIcon } from "../../models/ProjectContenent";


export const ProjectL: React.FC<projectsProps> = ({ title, detail, link, github, image, tools }) => {


    return (
        <Stack
            className="work"
            direction="row" alignItems="center"
            sx={{ width: "100%" }}
        >
            <Stack className="project-content "
                direction="column" gap={3}
                sx={() => ({
                    width: "45%",
                    marginRight: "-60px",
                    paddingTop: "10px"
                })}
            >
                <Typography variant="h5"
                    sx={(theme) => ({
                        color: theme.palette.mode === "dark" ?
                            'rgb(5, 175, 133)' : theme.palette.grey[700],
                        fontWeight: "bold",


                    })}
                >
                    {title}
                </Typography>
                <ProjectContent detail={detail} />
                <Stack direction="column" gap={1}
                >
                    <Stack direction="row" gap={1}>
                        {tools && tools.map((tool, index) => {
                            return (
                                <Typography
                                    sx={(theme) => ({
                                        color: theme.palette.mode === "dark" ?
                                            '#13bd93' : theme.palette.grey[700],
                                        fontSize: "13px"


                                    })}
                                    variant="subtitle2" key={index} >{tool}</Typography>
                            )
                        })}

                    </Stack>
                </Stack>
                <Stack gap={1} direction="row"
                    className="d_work_p_link"
                >
                    {link && <a href={link} className="mouse_interaction-target" target="_blank" rel="noopener noreferrer" title={`Explore more about ${title}- ${link}`}>
                        <Typography
                            sx={(theme) => ({
                                color: theme.palette.mode === "dark" ?
                                    '#13bd93' : theme.palette.grey[700],



                            })}>
                            <BsBoxArrowUpRight />
                        </Typography>

                    </a>}

                    {
                        github && <a href={github} className="mouse_interaction-target" target="_blank" rel="noopener noreferrer" title={`Check out ${title}'s codebase on GitHub - ${github}`}>
                            <Typography sx={(theme) => ({
                                color: theme.palette.mode === "dark" ?
                                    '#13bd93' : theme.palette.grey[700],
                                fontSize: "13px"


                            })}>  <BsGithub color="rgb(5, 175, 133)" /></Typography>

                        </a>
                    }



                </Stack>
            </Stack>
            <ProjectIcon image={image} />
        </Stack >
    )
}

