import { Stack, Box, Typography } from "@mui/material";
import { experienceData } from "../../mock/me";
import React from "react";
import { CardExperience } from "./card";
import "./experience.css";

import { useResponsive } from "../../hooks/use-responsive";
import { MobileProfile } from "../mobile-profile";
import { grey } from "../../theme/palette";


const Experience = () => {
  const upLg = useResponsive("up", "lg");

  return (
    <Stack id="expeience" direction="column" >
      {!upLg && <MobileProfile />}

      <Stack

        sx={{ marginTop: "40px" }}
        className="experience_h"
        direction="row"
        alignItems="center"

        justifyContent={upLg ? "left" : "space-between"}
      >
        <Typography
          variant="h3"
          sx={(theme) => ({
            color:
              theme.palette.mode === "dark"
                ? grey[850]
                : theme.palette.grey[900],
                fontSize:upLg ? '14px' :'25px !important'
          })}
          id="projectheader"
          className="targets-container">
          Experience{" "}
        </Typography>
        {upLg ? (
          <Box 
          sx={(theme) => ({
            backgroundColor:
              theme.palette.mode === "dark"
                ? 'grey[850]'
                : theme.palette.grey[900],
        
          })}

           id="projectlines" className="d_works_c_h_l  targets-container-width"></Box>
        ) : (
          <Box
            className="expe-right-box"
            sx={(theme) => ({
              borderTopColor:
                theme.palette.mode === "dark"
                  ? grey[850]
                  : "rgb(2, 138, 104)",
              borderRightColor:
                theme.palette.mode === "dark"
                  ?  grey[850]
                  : "rgb(2, 138, 104)",
            })}
          ></Box>
        )}
      </Stack>

      <Box
        className="exp-container"
        sx={(theme) => ({
          width: "100%",
          borderLeftColor: upLg
            ? theme.palette.mode === "dark"
              ? theme.palette.grey[700]
              : theme.palette.grey[300]
            : "transparent",
          paddingLeft: upLg ? "40px" : "0",
        })}
      >
        {experienceData &&
          experienceData.map((data, index) => {
            return (
              <Stack
                key={index}
                gap={4}
                direction="column"
                justifyContent="space-evenly"
              >
                <CardExperience
                  title={data.title && data.title.text}
                  session={data.date}
                  detail={data.detail}
                  logo={data.title && data.title.image}
                  skills={data.skills}
                  index={index && index}
                />
              </Stack>
            );
          })}
      </Box>

      {!upLg && (
        <Stack
          className="experience_h"
          direction="row"
          alignItems="center"
          justifyContent="left"
        >
          <Box
            className="expe-left-box"
            sx={(theme) => ({
              borderBottomColor:
                theme.palette.mode === "dark"
                  ?  grey[850]
                  : "rgb(2, 138, 104)",
              borderLeftColor:
                theme.palette.mode === "dark"
                  ?  grey[850]
                  : "rgb(2, 138, 104)",
            })}
          ></Box>
        </Stack>
      )}
    </Stack>
  );
};

export default Experience;
